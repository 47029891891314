import { PIIScanResultStatuses } from '@models/enums/PIIScanResultStatuses';
import { TransformationResultStatuses } from '@models/enums/TransformationResultStatuses';

export enum FilesHubOperationsNames {
  test = 'test',
}

export type FilesHubOperations = {
  [FilesHubOperationsNames.test]: (message?: string) => Promise<void>;
};

export enum FilesHubCallbacksNames {
  fileUploaded = 'FileUploaded',
  fileDeleted = 'FileDeleted',
  fileStatusUpdated = 'FileStatusUpdated',
  fileTransformationStatusUpdated = 'fileTransformationStatusUpdated',
  fileTransformationResultUpdated = 'FileTransformationResultUpdated',
  fileUserStatusUpdated = 'FileUserStatusUpdated',
  fileUpdated = 'FileUpdated',
  fileSubCategoryCreated = 'FileSubCategoryCreated',
  fileSubCategoryUpdated = 'FileSubCategoryUpdated',
  whitelistRecordCreated = 'WhitelistRecordCreated',
}

export type FilesHubCallbacks = {
  [FilesHubCallbacksNames.fileUploaded]: (data: { clientId: string; fileId: string }) => void;
  [FilesHubCallbacksNames.fileDeleted]: (fileId: string) => void;
  [FilesHubCallbacksNames.fileStatusUpdated]: (data: {
    clientId: string;
    fileId: string;
    status: number;
    statusMessage: PIIScanResultStatuses;
  }) => void;
  [FilesHubCallbacksNames.fileTransformationStatusUpdated]: (data: {
    clientId: string;
    fileId: string;
    status: number;
    statusMessage: TransformationResultStatuses;
  }) => void;
  [FilesHubCallbacksNames.fileTransformationResultUpdated]: (data: {
    clientId: string;
    fileId: string;
    transformationResult: string;
  }) => void;
  [FilesHubCallbacksNames.fileUpdated]: (data: { clientId: string; fileId: string }) => void;
  [FilesHubCallbacksNames.fileSubCategoryCreated]: (data: {
    clientId: string;
    productId: string;
    subCategoryId: string;
  }) => void;
  [FilesHubCallbacksNames.fileSubCategoryUpdated]: (data: {
    clientId: string;
    productId: string;
    subCategoryId: string;
  }) => void;
  [FilesHubCallbacksNames.whitelistRecordCreated]: (data: {
    clientId: string;
    whitelistRecordId: string;
  }) => void;
};

export interface FilesHub {
  callbacksName: FilesHubCallbacksNames;
  callbacks: FilesHubCallbacks;

  methodsName: FilesHubOperationsNames;
  methods: FilesHubOperations;
}
