import { Box, IconButton, Tooltip } from '@mui/material';
import { useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import { FileCard } from '../FileCard';
import { FileContentTypes } from '@models/constants/FileContentTypes';
import { FileSubCategoryCard } from '../FileSubCategoryCard';
import HelpCircleIcon from '@assets/icons/dashboard/help-circle.svg';
import { IFile } from '@models/interfaces/entities/IFile';
import { IFileCategory } from '@models/interfaces/entities/IFileCategory';
import { IFileSubCategory } from '@models/interfaces/entities/IFileSubCategory';
import { IUpdateFileSubCategoryData } from '@models/interfaces/additional/IUpdateFileSubCategoryData';
import { MappingDialog } from '../MappingDialog';
import { OldFileCard } from '../OldFileCard';
import SettingsIcon from '@assets/icons/dashboard/settings.svg';
import clsx from 'clsx';
import { useConfirm } from '@hooks';
import useStyles from './styles';

export interface IProps {
  category: IFileCategory;
  subCategory: IFileSubCategory;
  file?: IFile;
  oldFile?: IFile;
  onDrop: () => void;
  onUpdateFileSubCategory: (data: IUpdateFileSubCategoryData) => void;
  onViewFilePiiScanResultDetails: (file: IFile) => void;
  onDeleteFile: (file: IFile) => void;
  onDownloadFile: (file: IFile) => void;
  onFileDragStart: (file: IFile) => void;
  onFileDragEnd: (file: IFile) => void;
  configureMappingEnabled?: boolean;
}

export const FileSubCategoryMappingRow = ({
  category,
  subCategory,
  file,
  oldFile,
  onDrop,
  onUpdateFileSubCategory,
  onViewFilePiiScanResultDetails,
  onDeleteFile,
  onDownloadFile,
  onFileDragStart,
  onFileDragEnd,
  configureMappingEnabled,
}: IProps) => {
  const { classes } = useStyles();
  const confirm = useConfirm();

  const [openDialog, setOpenDialog] = useState(false);

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const onConfigureMapping = () => {
    setOpenDialog(true);
  };

  const onChangeActive = async (value: boolean) => {
    if (!subCategory.links[Actions.update]) return;

    let notes: string | undefined = undefined;

    if (!value) {
      const confirmationData = await confirm({
        title: 'Remove Data Input File',
        description:
          'Please tell us why you no longer plan to provide this file to be included in our analysis.',
        confirmationText: 'Confirm',
        showNotesInput: true,
        notesInputRequired: true,
      });

      notes = confirmationData?.notes;
    }

    onUpdateFileSubCategory({
      disabled: !value,
      notes,
    });
  };

  const hasNotBinaryContentType = useMemo(
    () => file?.contentType && !FileContentTypes.binary.includes(file.contentType),
    [file],
  );

  const notes = subCategory.disabled ? subCategory.statusNotes : subCategory.notes;

  return (
    <>
      <Box className={clsx([classes.root])}>
        {oldFile ? (
          <OldFileCard
            id={`old-file-${oldFile.id}`}
            key={oldFile.id}
            item={oldFile}
            onDownload={() => onDownloadFile(oldFile)}
            isActive={!subCategory.disabled}
            onChangeActive={onChangeActive}
            changeDisabled={!!file}
          />
        ) : (
          <div />
        )}
        <Box className={classes.infoSection}>
          {configureMappingEnabled &&
          file?.links[Actions.changeMapping] &&
          hasNotBinaryContentType ? (
            <Tooltip title={notes}>
              <IconButton onClick={onConfigureMapping} data-testid='settings-button'>
                <img src={SettingsIcon} alt='settings' />
              </IconButton>
            </Tooltip>
          ) : (
            notes && (
              <Tooltip title={notes}>
                <IconButton data-testid='help-button'>
                  <img src={HelpCircleIcon} alt='help' />
                </IconButton>
              </Tooltip>
            )
          )}
        </Box>
        {(!subCategory.disabled || file) && (
          <>
            {file ? (
              <FileCard
                key={file.id}
                item={file}
                hideUserStatus
                extendedName
                draggable={!!file.links[Actions.changeCategory]?.href}
                onDetails={() => onViewFilePiiScanResultDetails(file)}
                onDelete={() => onDeleteFile(file)}
                onDownload={() => onDownloadFile(file)}
                onDragStart={() => onFileDragStart(file)}
                onDragEnd={() => onFileDragEnd(file)}
              />
            ) : (
              <FileSubCategoryCard
                id={`file-sub-category-${subCategory.id}`}
                key={subCategory.id}
                item={subCategory}
                onDrop={onDrop}
              />
            )}
          </>
        )}
      </Box>
      {file && (
        <MappingDialog
          file={file}
          fileCategory={category}
          fileSubCategory={subCategory}
          open={openDialog}
          onClose={onCloseDialog}
        />
      )}
    </>
  );
};
