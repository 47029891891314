import * as t from 'io-ts';

import { IJsonDMAccountData, IJsonDMAccountDataSchema } from './IJsonDMAccountData';
import { IJsonGLAccountData, IJsonGLAccountDataSchema } from './IJsonGLAccountData';

export interface IJsonGLSubAccountsData {
  Accounts: IJsonGLAccountData[];
  GLTotalAccounts: IJsonGLAccountData[];
  DMAccounts: IJsonDMAccountData[];
  TotalAccounts: IJsonDMAccountData[];
  sLoanPushBalancing: string[];
  sInvestmentPushBalancing: string[];
  sAssetPushBalancing: string[];
  sShareCDPushBalancing: string[];
  sBorrowingPushBalancing: string[];
  sLiabilityPushBalancing: string[];
}

export const IJsonGLSubAccountsDataSchema = t.type({
  Accounts: t.array(IJsonGLAccountDataSchema),
  GLTotalAccounts: t.array(IJsonGLAccountDataSchema),
  DMAccounts: t.array(IJsonDMAccountDataSchema),
  TotalAccounts: t.array(IJsonDMAccountDataSchema),
  sLoanPushBalancing: t.array(t.string),
  sInvestmentPushBalancing: t.array(t.string),
  sAssetPushBalancing: t.array(t.string),
  sShareCDPushBalancing: t.array(t.string),
  sBorrowingPushBalancing: t.array(t.string),
  sLiabilityPushBalancing: t.array(t.string),
});
