import { Mapper, createMap, forMember, mapFrom } from '@automapper/core';

import { IFile } from '@models/interfaces/entities/IFile';
import { IFileDto } from '@models/interfaces/dtos/IFileDto';
import { PojosMetadataMap } from '@automapper/pojos';

export const createFileMapping = (mapper: Mapper) => {
  PojosMetadataMap.create<IFileDto>('IFileDto', {
    id: String,
    fileType: Number,
    contentType: String,
    fileTypeMessage: String,
    name: String,
    alternativeName: String,
    uploadedBy: String,
    uploaded: Number,
    size: Number,
    contentId: String,
    clientId: String,
    clientName: String,
    projectId: String,
    projectName: String,
    productId: String,
    piiScanResultStatus: Number,
    piiScanResultStatusMessage: String,
    transformationResultStatus: Number,
    transformationResultStatusMessage: String,
    transformationResultMessage: String,
    contentTransformationResultStatus: Number,
    contentTransformationResultStatusMessage: String,
    userStatus: Number,
    userStatusMessage: String,
    statusApprover: String,
    fileCategoryId: String,
    fileSubCategoryId: String,
    fileSubCategoryMappingId: String,
    fileSubCategoryTransformationScriptId: String,
    sourceFileId: String,
    sourceFileName: String,
    parts: ['IFileDto'],
    isSource: Boolean,
    hasCategorizedParts: Boolean,
    hasDeletedParts: Boolean,
    hasRejectedParts: Boolean,
    ignored: Number,
    links: 'ILinksDto',
  });

  PojosMetadataMap.create<IFile>('IFile', {
    id: String,
    fileType: String,
    contentType: String,
    name: String,
    alternativeName: String,
    uploadedBy: String,
    uploaded: Date,
    size: Number,
    contentId: String,
    clientId: String,
    clientName: String,
    projectId: String,
    projectName: String,
    productId: String,
    piiScanResultStatus: String,
    transformationResultStatus: String,
    transformationResultMessage: String,
    contentTransformationResultStatus: String,
    userStatus: String,
    statusApprover: String,
    fileCategoryId: String,
    fileSubCategoryId: String,
    fileSubCategoryMappingId: String,
    fileSubCategoryTransformationScriptId: String,
    sourceFileId: String,
    sourceFileName: String,
    parts: ['IFile'],
    isSource: Boolean,
    hasCategorizedParts: Boolean,
    hasDeletedParts: Boolean,
    hasRejectedParts: Boolean,
    ignored: Date,
    links: 'ILinks',
  });

  createMap<IFileDto, IFile>(
    mapper,
    'IFileDto',
    'IFile',
    forMember(
      (destination) => destination.uploaded,
      mapFrom((source) => new Date(source.uploaded)),
    ),
    forMember(
      (destination) => destination.fileType,
      mapFrom((source) => source.fileTypeMessage),
    ),
    forMember(
      (destination) => destination.piiScanResultStatus,
      mapFrom((source) => source.piiScanResultStatusMessage),
    ),
    forMember(
      (destination) => destination.transformationResultStatus,
      mapFrom((source) => source.transformationResultStatusMessage),
    ),
    forMember(
      (destination) => destination.contentTransformationResultStatus,
      mapFrom((source) => source.contentTransformationResultStatusMessage),
    ),
    forMember(
      (destination) => destination.userStatus,
      mapFrom((source) => source.userStatusMessage),
    ),
    forMember(
      (destination) => destination.ignored,
      mapFrom((source) => (source.ignored ? new Date(source.ignored) : null)),
    ),
  );
};
