import { Accept, useDropzone } from 'react-dropzone';
import { Box, Button, Tooltip } from '@mui/material';
import { useApi, useLoader } from '@hooks';

import { Actions } from '@models/enums/Actions';
import { IJsonAccountData } from '@models/interfaces/additional/IJsonAccountData';
import { IProject } from '@models/interfaces/entities/IProject';
import { IUpdateAccountsDataAccount } from '@models/interfaces/additional/IUpdateAccountsData';
import { Loader } from '@components/Loader';
import UploadIcon from '@assets/icons/dashboard/upload.svg';
import mapper from '@models/mapper';
import { toast } from 'react-toastify';
import { updateAccounts } from '@services/api';
import { useCallback } from 'react';

const otherAccounts = [
  'Cash',
  'OtherAsset',
  'OtherLiab',
  'AvailableForSaleAllow',
  'AllowanceForCreditLoss',
  'BalInvest',
  'BalLiab',
  'Capital',
  'ReserveInvestGainLoss',
  'NIIIndirect',
  'NIIDirectLoans',
  'NIIDirectInvest',
  'NIILoanService',
  'NIEIndirect',
  'NIELoanService',
  'NIEProvLoanLoss',
  'NIEDirectLoans',
  'NIEDirectInvest',
  'NIEAvailSaleGainLoss',
];

interface IProps {
  id?: string;
  project: IProject;
  maxFileSize: number;
  accept: Accept | undefined;
}

export const AccountsImporter = ({ project, maxFileSize, accept }: IProps) => {
  const { request: updateAccountsRequest, loading: updateAccountsLoading } = useApi(
    updateAccounts,
    null,
    {
      handleErrors: true,
      onCallback: () => {
        toast.info('Successfully imported the accounts');
      },
    },
  );

  const onDropFiles = useCallback(async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length || !project.links[Actions.importAccounts]?.href) return;
    const [file] = acceptedFiles;

    const fileContent = await file.text();

    try {
      const jsonData = JSON.parse(fileContent);
      const accountsB = jsonData.AccountsB;

      if (Array.isArray(accountsB)) {
        const mappedRegularAccounts: IUpdateAccountsDataAccount[] = [];
        const mappedSummaryAccounts: IUpdateAccountsDataAccount[] = [];

        accountsB.forEach((account) => {
          const mappedAccount = mapper.map<IJsonAccountData, IUpdateAccountsDataAccount>(
            account,
            'IJsonAccountData',
            'IUpdateAccountsDataAccount',
          );

          if (mappedAccount.sumCode === 0) {
            mappedRegularAccounts.push(mappedAccount);
          } else if (mappedAccount.sumCode === 1) {
            mappedSummaryAccounts.push(mappedAccount);
          } else if (mappedAccount.sumCode === 2) {
            if (mappedSummaryAccounts.length > 0) {
              const lastSummaryAccount = mappedSummaryAccounts[mappedSummaryAccounts.length - 1];
              if (!Array.isArray(lastSummaryAccount.detailAccounts)) {
                lastSummaryAccount.detailAccounts = [mappedAccount];
              } else {
                lastSummaryAccount.detailAccounts.push(mappedAccount);
              }
            } else {
              throw new Error('Detected the detail account without related summary account');
            }
          }
        });

        const otherMappedAccounts = otherAccounts
          .map((accountName) => {
            const acc = jsonData[accountName] as { Name: string };
            return {
              fileId: acc.Name,
              accountName: acc.Name,
              accountCategory: '',
              sumCode: 0,
              downloadId: '',
              accountType: -1,
            } as IUpdateAccountsDataAccount;
          })
          .filter((x) => !!x.accountName);

        updateAccountsRequest(project.links[Actions.importAccounts].href, [
          ...mappedRegularAccounts,
          ...mappedSummaryAccounts,
          ...otherMappedAccounts,
        ]);
      } else {
        toast.error('Accounts list or does not exist in the file.');
      }
    } catch (error) {
      console.error('Error parsing JSON file:', error);
      toast.error('Failed to parse the file.');
    }
  }, []);

  const dropzone = useDropzone({
    accept,
    multiple: false,
    noDragEventsBubbling: true,
    preventDropOnDocument: false,
    onDrop: onDropFiles,
    onDropRejected(fr) {
      toast.info(
        `File skipped due to ${
          fr[0]?.errors[0] ? `error: ${fr[0]?.errors[0].message}` : 'unknown error'
        }`,
      );
    },
    maxSize: maxFileSize * 1024 * 1024,
  });

  const onUploadButtonClicked = () => {
    dropzone.open();
  };

  const showLoader = useLoader(updateAccountsLoading);

  return (
    <>
      {project.links[Actions.importAccounts]?.href && (
        <>
          <Tooltip title='Import accounts'>
            <Button
              onClick={onUploadButtonClicked}
              variant='outlined'
              size='small'
              color='info'
              startIcon={<img alt='upload' src={UploadIcon} />}
            >
              Import accounts
            </Button>
          </Tooltip>
          <Box {...dropzone.getRootProps()}>
            <input data-testid='dropzone-input' {...dropzone.getInputProps()} />
          </Box>
        </>
      )}
      <Loader show={showLoader} />
    </>
  );
};

AccountsImporter.defaultProps = {
  maxFileSize: 10,
  accept: {
    'text/json': ['.json'],
  },
};
